'use strict';

define('vb/action/builtin/transformChartDataAction',[
  'vb/private/utils',
  'vb/action/action',
  'vb/private/log'],
  (Utils, Action, Log) => {
    const logger = Log.getLogger('/vb/action/builtin/TransformChartDataAction');
    const MAPPING_DEF_3D = {
      group: 'group',
      series: 'series',
      valueX: 'valueX',
      valueY: 'valueY',
      valueZ: 'valueZ',
    };

    const MAPPING_DEF_1D = {
      group: 'group',
      series: 'series',
      value: 'value',
    };

    /**
     * Transforms a JSON array into another JSON object in a form that chart components can
     * consume. The JSON object has 2 properties series and groups that are both arrays.
     */
    class TransformChartDataAction extends Action {
      constructor(id, label) {
        super(id, label);
        this.log = logger;
      }

      /**
       * Performs the transformation to chart JSON data.
       *
       * returns a Promise, that is either:
       * - resolved with an Outcome, either success or failure
       * - rejected, no value
       *
       *
       * @param {Object} parameters that this action accepts.
       *  - source: array, the source JSON array that needs to be transformed to chart data. The
       *  source is always an array of objects, or data points, where each data point has
       * either of these structures. The first is used with charts that show groups of data for
       * one or more series. The latter used with charts that show 3 dimensions of data. group,
       * series and value* are the keys in the object.
       *
       *   {
       *     group: '<group-name>',
       *     series: '<series-name>',
       *     value: '<value-number>'
       *   }
       *
       *   {
       *     group: '<group-name>',
       *     series: '<series-name>',
       *     valueX: '<number>',
       *     valueY: '<number>',
       *     valueZ: '<number>'
       *   }
       *
       * @returns {*}
       */
      perform(parameters) {
        const source = parameters.source;
        let ret;

        if (source && !Array.isArray(source)) {
          this.log.error('Inputs to TransformChartDataAction should be an array, even if it is a single item.');
          return Promise.reject();
        }

        try {
          ret = TransformChartDataAction.transform(source);
        } catch (e) {
          const msg = `TransformChartDataAction: error transforming source data: ${source}`;
          this.log.error(msg, e);
          // messaging
          return Promise.resolve(Action.createFailureOutcome(msg, e));
        }

        return Promise.resolve(ret).then(
          result => Promise.resolve(Action.createSuccessOutcome(result)));
      }

      /**
       * Transforms the source values into the targetChartModel, where the source has a specific
       * structure.
       *
       * @param source {Array} the source data
       *
       *  @returns {Object} a chartModel with 2 properties - series and groups as expected by
       *  UI component. See JET oj-chart for details.
       *  @static
       */
      static transform(source) {
        if (source && source.length > 0) {
          const mappingOrder = ['group', 'series', 'value'];
          let mappingDefs;
          const sampleRow = Array.isArray(source) && source[0] ? source[0] : {};
          if (sampleRow.valueX) {
            mappingDefs = MAPPING_DEF_3D;
          } else {
            mappingDefs = MAPPING_DEF_1D;
          }

          // (1) build a grid to hold the series and group data
          // - (a) get list of ordered and unique group names and series names
          const groupPositionList = []; // order of groupName (row position) in grid
          const seriesPositionList = [];// order of seriesName (col position) in grid

          mappingOrder.forEach((targetVar) => {
            if (targetVar === 'group') {
              source.forEach(($sourceRow) => {
                const groupName = $sourceRow[targetVar];
                if (groupName) {
                  if (groupPositionList.indexOf(groupName) < 0) {
                    groupPositionList.push(groupName);
                  }
                } else {
                  logger.info('TransformChartDataAction: there is no group set!');
                }
              });
            }

            if (targetVar === 'series') {
              source.forEach(($sourceRow) => {
                const seriesName = $sourceRow[targetVar];
                if (seriesName) {
                  if (seriesPositionList.indexOf(seriesName) < 0) {
                    seriesPositionList.push(seriesName);
                  }
                } else {
                  logger.info('TransformChartDataAction: there is no series set!');
                }
              });
            }
          });

          // - (b) fill grid with data by group and series
          const datagrid =
            TransformChartDataAction.fillGridData(seriesPositionList, groupPositionList,
              source, mappingDefs);

          // (2) generate chart data from data grid
          return TransformChartDataAction.generateChartData(datagrid);
        }
        return { groups: [], series: [] };
      }

      /**
       * Fill a grid so headers are the series names, and the first column the group names.
       * Populate rest of grid with series and group data values.
       *
       * cols: contain 'Group' header + one for every Series name
       * rows: contain group name + data for (group, series) pair in cell
       * So a populated grid will look like this
       *
       * ||   Group   ||  Series 1   ||   Series 2  ||  Series N  ||
       * |    G1      |      1        |     3       |     5        |
       * |    G1      |      4        |     6       |     8        |
       * |    G1      |      2        |             |     9        |
       *
       * @param seriesList
       * @param groupList
       * @param source
       * @param mapping
       *
       * @returns {{cols: Array, rows: Array}}
       * @static
       */
      static fillGridData(seriesList, groupList, source, mapping) {
        let startColIndex = 1;
        const groupDef = mapping.group;
        const seriesDef = mapping.series;
        const value3D = !!mapping.valueX;

        // if we have no series shove empty series header
        let cols = [];
        if (seriesList.length === 0) {
          seriesList.push('__series__');
        }
        cols = new Array(seriesList.length + 1);
        cols[0] = '__group__';

        // rows: initialize
        const rows = new Array(groupList.length);
        for (let i = 0; i < groupList.length; i += 1) {
          rows[i] = new Array(seriesList.length + 1);
          rows[i][0] = groupList[i];
        }

        seriesList.forEach((seriesName) => {
          cols[startColIndex] = seriesName;
          startColIndex += 1;
        });

        // (d) populate data values
        source.forEach((sourceRow) => {
          let $rowIndex;
          let $colIndex;

          // get the x,y position in grid to shove the value
          const $group = sourceRow[groupDef];
          if ($group) {
            $rowIndex = groupList.indexOf($group);
          } else {
            logger.error('TransformChartDataAction: need at least one group defined');
          }

          const $series = sourceRow[seriesDef];
          if ($series) {
            $colIndex = seriesList.indexOf($series);
          } else {
            $colIndex = 0; // no series assume first column
          }

          const $value = value3D ? { x: sourceRow[mapping.valueX],
                                     y: sourceRow[mapping.valueY],
                                     z: sourceRow[mapping.valueZ] } :
                                    sourceRow[mapping.value];
          if ($rowIndex !== undefined && $colIndex !== undefined) {
            rows[$rowIndex][$colIndex + 1] = $value;
          } else {
            logger.warn('TransformChartDataAction: unable to locate a series and group for the value', $value);
          }
        });

        return { cols, rows };
      }

      /**
       * Given a data grid returns a json model that chart expects.
       *
       * @param datagrid
       * @returns {{}}
       */
      static generateChartData(datagrid) {
        const result = {};
        const cols = datagrid.cols;
        const rows = datagrid.rows;
        if (cols && rows) {
          cols.forEach((colData, colIndex) => {
            if (colIndex === 0) {
              result.series = [];
              // build groups data
              const groupsData = [];
              rows.forEach((row) => {
                groupsData.push(row[colIndex]);
              });
              result.groups = groupsData;
            } else {
              // build series data
              const seriesData = { name: colData };
              result.series.push(seriesData);
              const itemsData = [];
              rows.forEach((row) => {
                itemsData.push(row[colIndex]);
              });
              seriesData.items = itemsData;
            }
          });
        }

        return result;
      }
    }

    return TransformChartDataAction;
  });

