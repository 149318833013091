'use strict';

define('vbsw/private/plugins/authPostprocessorHandlerPlugin',[
  'vbsw/api/fetchHandlerPlugin',
  'vbsw/private/constants',
  'vbsw/private/headerUtils',
], (FetchHandlerPlugin, Constants, HeaderUtils) => {
  /**
   * Handler plugin for removing headers added by AuthPreprocessorHandlerPlugin.
   */
  class AuthPostprocessorHandlerPlugin extends FetchHandlerPlugin {
    /**
     * Remove intra plugin headers, to prevent CORS issues later.
     *
     * May also prefix headers, when using the newer VB proxy:
     * Here is an example URL, prefixed with the "1.1" proxy:
     */
    // eslint-disable-next-line max-len
    // https://<vb host>/ic/builder/design/MyAppName/1.0;profile=base_configuration/services/auth/1.1/proxy/postmanEchoCom/uri/https/postman-echo.com/post

    handleRequestHook(request) {
      const headersToDelete = [];
      const headersToPrefix = [];

      // if this is present, we need to transform certain header names so the V1.1 VB Proxy will pass them along.
      const nameStr = request.headers.get(Constants.PROXY_HEADERNAME_HEADER);
      let originalHeaderNamesForNewProxy;
      try {
        originalHeaderNamesForNewProxy = nameStr ? JSON.parse(nameStr).map((name) => name.toLowerCase()) : null;
      } catch (e) {
        console.error('Error parsing value for internal header', Constants.PROXY_HEADERNAME_HEADER, e);
      }

      // On Firefox and IE, deleting the headers while iterating messes up the iterator. Work around
      // the issue by using an array, headersToDelete, to keep track of headers to delete.
      for (const header of request.headers.keys()) {
        // if we are using the V1.1+ VB proxy, we may need to change the header name
        if (originalHeaderNamesForNewProxy && HeaderUtils.needsPrefixForProxy(header, originalHeaderNamesForNewProxy)) {
          headersToPrefix.push(header);
        } else if (AuthPostprocessorHandlerPlugin.VB_HEADER_REGEX.test(header)
          && !header.startsWith(Constants.HEADER_PROXY_PREFIX)) {
          // delete all "vb-*", except for "vb-proxy-*"
          headersToDelete.push(header);
        }
      }

      headersToPrefix.forEach((header) => {
        HeaderUtils.prefixHeaderForProxy(request, header);
      });

      headersToDelete.forEach((header) => {
        request.headers.delete(header);
      });

      return Promise.resolve();
    }
  }

  // static member, for unit tests
  AuthPostprocessorHandlerPlugin.VB_HEADER_REGEX = /^vb-.*/;

  return AuthPostprocessorHandlerPlugin;
});

