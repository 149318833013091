'use strict';

define('vb/private/types/personalization',[
  'vb/helpers/mixin',
  'vb/private/types/builtinExtendedTypeMixin',
  'vb/private/log',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/stateUtils',
], (Mix, BuiltinExtendedTypeMixin, Log, Router, StateUtils) => {
  const LOGGER = Log.getLogger('/vb/types/Personalization');

  // base class for Personalization extended type
  class PziBase {
    // There is a Babel issue when Object is used as the base for Personalization, ie. extends Mix(Object) ...
    // The issue manifests in StateUtils.instantiateType() where
    // newInstance.isExtendedType is undefined (in samples-release, but not samples)
    // Defining this dummy PziBase class resolves that issue. See:
    // https://github.com/babel/babel/issues/5285
  }

  /**
   * Extended type for Personalization variables.
   * A variable based on this type has the following configurable properties:
   *  - uniqueId:       identifies the variable usage in the personalization store
   *  - data:           object containing state to be recorded into personalization store
   */
  class Personalization extends Mix(PziBase).with(BuiltinExtendedTypeMixin) {
    /**
     * @constructor
     */
    constructor() {
      super();
      this.log = LOGGER;
    }

    init(id, variableDef, defaultValue) {
      super.init(id, variableDef, defaultValue);
      this._personalizationProvider = Router.application.personalizationProvider;
      if (!this._personalizationProvider) {
        this.log.warn('Personalization provider is not properly configured. No personalization data is loaded.',
          'Future personalization changes will not be persisted.');
        return;
      }
      const localUserState = this._personalizationProvider.getLocalUserState(defaultValue.uniqueId);
      if (localUserState) {
        // eslint-disable-next-line no-param-reassign
        defaultValue.data = localUserState;
      }

      // TODO: register the event listener for a new vbVariableVBEnter event. This event will be
      // fired after all variables have been initialized and right before the vbEnter event is
      // fired. This would be an opportunity for variables of internal built-in types to tweak
      // its value. This is currently only needed by  Personalization type since it overwrites
      // the value (evaluated from defaultValue) to the one stored in store. Also the code in
      // init needs to move into this event listener method.
      // Please log a bug if this feature is needed.
      // container.addEventListener('vbVariableVBEnter');
    }

    /**
     * Called when the value of the variable that is mapped to an instance of this type is changed.
     * See StateUtils.instantiateType and StateUtils.addInstancePropertyToScope
     *
     * Properties can change in the following ways:
     * 1. UI field is bound to a variable-property and the UI writes to the property
     * 2. an assign variable action writes to this variable property
     *
     * @private
     * @param e Event payload holding the name, oldValue, value and diff properties. See
     * variable.js for details.
     */
    handlePropertyVariableChangeEvent(e) {
      // at this point we know that there was a diff on the variable (see Variable.dispatchChangeEvent)
      // and, there is rate limiting/throttling control at that level to avoid excessive frequency of calls
      // to this handler

      // currently the setValue call in init can trigger this change event if it occurs after
      // the redux store is available
      // TODO: distinguish between this case and the case of a legitimate variable change event

      if (!this._personalizationProvider) {
        return;
      }
      this._personalizationProvider.handleUserStateChange(e.value.uniqueId, e.value.data);
    }

    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition(variableDef, typeResolvers) {
      const dataTypeDef = (variableDef.defaultValue && variableDef.defaultValue.dataType)
        ? variableDef.defaultValue.dataType : 'object';
      return {
        type: {
          uniqueId: 'string',
          dataType: 'any',
          data: StateUtils.getType('data', { type: dataTypeDef }, typeResolvers),
        },
        resolved: true,
      };
    }
  }

  return Personalization;
});

